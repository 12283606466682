<template>
  <Toast />
  <div class="fundo">
    <div class="container" style="margin-top: 250px">
      <div class="p-col-12 p-md-3">
        <div class="p-shadow-4 login">
          <div class="bodyForm">
            <div class="p-d-flex p-ai-center p-jc-center p-fluid p-grid">
              <div class="p-field formulario">
                <div class="p-d-flex p-ai-center p-jc-center p-mb-6">
                  <img src="assets/layout/images/sespa.png" alt="Logo Sespa" class="responsive" />
                </div>
                <form @submit.prevent="searchData()">
                  <div class="field">
                    <InputNumber id="mf" mode="decimal" :useGrouping="false" v-model="form.matricula"
                      placeholder="Digite sua Matrícula. (Sem Dígito)"
                      :class="{ 'p-invalid': submitted && !form.matricula }" autofocus />
                    <small class="p-invalid" v-if="submitted && !form.matricula">Matrícula Funcional é
                      obrigatório.</small>
                  </div>
                  <br />
                  <div class="field">
                    <InputMask id="cpf" v-model.trim="form.cpf" mask="999.999.999-99" placeholder="Digite o CPF."
                      :class="{ 'p-invalid': submitted && !form.cpf }" />
                    <small class="p-invalid" v-if="submitted && !form.cpf">Cpf é obrigatório.</small>
                  </div>
                  <br />
                  <div class="field">
                    <Calendar id="data" v-model="form.data" view="month" dateFormat="mm/yy"
                      placeholder="Informe mês/ano. Ex: mm/yyyy" :yearNavigator="true" yearRange="2000:2030" />
                  </div>
                  <br />
                  <div class="p-d-flex p-ai-center p-jc-center">
                    <Button
                      label="CONSULTAR"
                      class="p-button p-mb-3 p-mr-3"
                      icon="pi pi-search"
                      type="submit"
                    ></Button>
                    <Button
                      label="RETORNAR"
                      class="p-button-secondary p-mb-3"
                      icon="pi pi-refresh"
                      @click="$router.push('/')"
                    ></Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Moment from "moment";
import AuthService from "@/service/Auth/AuthService";
import PessoaService from "@/service/Pessoa/PessoaService";
import BatidaService from "@/service/Batida/BatidaService";

import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default {
  data() {
    return {
      form: {
        matricula: null,
        cpf: null,
        data: null,
      },
      pessoa: {
        nome: null,
        mf: null,
        situacao: null,
        cargo: null,
        funcao: null,
        atuacao: null,
        horario: null,
      },
      showRegistros: false,
      showLogin: true,
      horasTotal: null,
      registros: null,
      submitted: false,
    };
  },
  authService: null,
  pessoaService: null,
  batidaService: null,  
  created() {
    this.authService = new AuthService();
    this.pessoaService = new PessoaService();
    this.batidaService = new BatidaService();
  },
  methods: {
    searchData() {
      this.submitted = true;      
      if (!this.form.matricula || !this.form.cpf || !this.form.data) {
        return;
      }
      
      let dataParams = {
        matricula: this.form.matricula.toString(),
        cpf: this.form.cpf,
        ano: this.form.data.getFullYear().toString(),
        mes: (this.form.data.getMonth() + 1).toString(),
      };

      this.dadosUser(this.form.cpf);

      this.batidaService     
        .relatorioMensal(dataParams)
        .then((data) => {
          this.registros = data;   

          const pdf = new jsPDF();

          const columns = ["Dia-Data", "Batidas", "Observações"];
          const dataParams = this.registros.map((registro) => [
            `${registro.day} - ${registro.work}`,
            this.formatBatidas(registro.clock),
            this.formatObservacoes(registro.direction),
          ]);

          // Add SESPA logo
          pdf.addImage("assets/layout/images/sespa.png", "JPEG", 65, 10, 80, 15);
          // Set font size
          pdf.setFontSize(6.5);

          pdf.text(`NOME: ${this.pessoa[0][0]}.`, 25, 29.5);

          pdf.text(`MATRÍCULA: ${this.pessoa[0][1]}.`, 25, 31.5);
          if (this.pessoa[0][3]) {
            pdf.text(`CARGO: ${this.pessoa[0][3]}.`, 25, 33.5);
          } else {
            pdf.text(`CARGO: SEM CARGO.`, 25, 33.5);
          }
          if (this.pessoa[0][4]) {
            pdf.text(`FUNÇÃO: ${this.pessoa[0][4]}.`, 25, 35.5);
          } else {
            pdf.text(`FUNÇÃO: SEM FUNÇÃO.`, 25, 35.5);
          }
          pdf.text(`ATUAÇÃO: ${this.pessoa[0][5]}.`, 25, 37.5);
          pdf.text(`SITUAÇÃO: ${this.pessoa[0][2]}.`, 25, 39.5);

          if (this.pessoa[0][6] === null) {
            pdf.text(`HORÁRIO: 00:00:00.`, 25, 41.5);
          } else {
            pdf.text(`HORÁRIO: ${this.pessoa[0][6]}.`, 25, 41.5);
          }
          pdf.text(`REFERÊNCIA: ${this.mesAno.toUpperCase()}. `, 25, 43.5);


          pdf.setFont("", "bold").setFontSize(10).text('RELATÓRIO MENSAL', 105, 50, null, null, "center");

          pdf.autoTable({
            head: [columns],
            body: dataParams,
            startY: 55,
            margin: { top: 55, left: 25 },
            styles: {
              fontSize: 6.5,
              cellPadding: 1.7,
              valign: 'middle',
              halign: '',
              fillColor: [255, 255, 255], // Cor de fundo do cabeçalho (Azul claro)
              textColor: [0, 0, 0], // Cor do texto do cabeçalho (Azul mais escuro)
              lineWidth: 0.3, // Espessura da borda
            },
            alternateRowStyles: { fillColor: [192, 192, 192] }, // Cor de fundo para linhas alternadas (Verde claro)
            columnStyles: {
              0: { cellWidth: 50 }, // Ajuste a largura da coluna conforme necessário
              1: { cellWidth: 40 },
              2: { cellWidth: 70 },
            },
            headeStyles: {
              theme: 'striped', // Adiciona a seta de ordenação nas colunas do cabeçalho
              fillColor: [21, 111, 183], // Cor de fundo do cabeçalho (Azul claro)
              textColor: [255, 255, 255],
            },
            bodyStyles: {
              rowAccessories: { fillColor: [255, 255, 255] }, // Cor de fundo padrão para outras linhas (Branco)
            },
          });

          const totalPagesExp = pdf.internal.getNumberOfPages();
          const pageStr = "{total_pages_count_string}";

          const textToDisplay = `PÁG. ${pageStr.replaceAll("{total_pages_count_string}", totalPagesExp)} / ${totalPagesExp}`;

          pdf.setFontSize(10);
          pdf.text(textToDisplay, 105, pdf.internal.pageSize.height - 10, {
            align: "center",
          });

          /*
          pdf.setFont("helvetica", "normal")
            .setFontSize(9.5)
            .text(
              "Coordenação de Tecnologia e Informática em Saúde - CTIS \n" +
              "End: Tva. Lomas Valentinas, 2190, Bairro Marco, CEP: 66093-677, Belém, Pará \n" +
              "Contato: +55, 91 4006-4399 | 4006-4328 www.saude.pa.gov.br",
              105,
              270,
              null,
              null,
              "center"
            );
          */
          window.open(pdf.output("bloburl"));
        })
        .catch((error) => {
          console.log(error)
        });
    },
    formatBatidas(batidas) {
      if (batidas) {
        // Adiciona "|" entre as batidas
        return batidas.replace(/s+/g, '|');
      } else {
        return 'X';
      }
    },
    formatObservacoes(observacoes) {
      if (observacoes) {
        return observacoes.replace(/s+/g, '|');
      } else {
        return 'X';
      }
    },
    exportarRelatorioPDF() {
      const pdf = new jsPDF();
      // Add SESPA logo
      pdf.addImage("assets/layout/images/sespa.png", "JPEG", 65, 10, 80, 15);
      // Set font size
      pdf.setFontSize(6.5);

      pdf.text(`Nome: ${this.pessoa[0][0]}.`, 25, 29.5);


      pdf.text(`Matricula: ${this.pessoa[0][1]}.`, 25, 31.5);
      if (this.pessoa[0][3]) {

        pdf.text(`Cargo: ${this.pessoa[0][3]}.`, 25, 33.5);
      } else {
        pdf.text(`Cargo: SEM CARGO.`, 25, 33.5);
      }
      if (this.pessoa[0][4]) {
        pdf.text(`Função: ${this.pessoa[0][4]}.`, 25, 35.5);
      } else {
        pdf.text(`Função: SEM FUNÇÃO.`, 25, 35.5);
      }
      pdf.text(`Atuação: ${this.pessoa[0][5]}.`, 25, 37.5);
      pdf.text(`Situação: ${this.pessoa[0][2]}.`, 25, 39.5);

      if (this.pessoa[0][6] === null) {
        pdf.text(`Horario: 00:00:00.`, 25, 41.5);
      } else {
        pdf.text(`Horario: ${this.pessoa[0][6]}.`, 25, 41.5);
      }
      pdf.text(`Referencia: ${this.mesAno.toUpperCase()}. `, 25, 43.5);

      pdf.setFont("", "bold").setFontSize(10).text('RELATÓRIO MENSAL', 105, 50, null, null, "center");

      const columns = ["Dia-Data", "Batidas", "Observações"];
      const data = this.registros.map((registro) => [
        `${registro.day} - ${registro.work}`,
        this.formatBatidas(registro.clock),
        this.formatObservacoes(registro.direction),
      ]);

      pdf.autoTable({

        head: [columns],
        body: data,
        startY: 55,
        margin: { top: 55, left: 25 },
        styles: {
          fontSize: 6.5,
          cellPadding: 1.7,
          valign: 'middle',
          halign: '',
          fillColor: [255, 255, 255], // Cor de fundo do cabeçalho (Azul claro)
          textColor: [0, 0, 0], // Cor do texto do cabeçalho (Azul mais escuro)
          lineWidth: 0.3, // Espessura da borda
        },
        alternateRowStyles: { fillColor: [192, 192, 192] }, // Cor de fundo para linhas alternadas (Verde claro)
        columnStyles: {
          0: { cellWidth: 50 }, // Ajuste a largura da coluna conforme necessário
          1: { cellWidth: 40 },
          2: { cellWidth: 70 },
        },
        headeStyles: {
          theme: 'striped', // Adiciona a seta de ordenação nas colunas do cabeçalho
          fillColor: [21, 111, 183], // Cor de fundo do cabeçalho (Azul claro)
          textColor: [255, 255, 255],
        },
        bodyStyles: {
          rowAccessories: { fillColor: [255, 255, 255] }, // Cor de fundo padrão para outras linhas (Branco)
        },
      });

      const totalPagesExp = pdf.internal.getNumberOfPages();
      const pageStr = "{total_pages_count_string}";

      const textToDisplay = `PÁG. ${pageStr.replaceAll("{total_pages_count_string}", totalPagesExp)} / ${totalPagesExp}`;

      pdf.setFontSize(10);
      pdf.text(textToDisplay, 105, pdf.internal.pageSize.height - 10, {
        align: "center",
      });

      /*
      pdf.setFont("helvetica", "normal")
        .setFontSize(9.5)
        .text(
          "Coordenação de Tecnologia e Informática em Saúde - CTIS \n" +
          "End: Tva. Lomas Valentinas, 2190, Bairro Marco, CEP: 66093-677, Belém, Pará \n" +
          "Contato: +55, 91 4006-4399 | 4006-4328 www.saude.pa.gov.br",
          105,
          270,
          null,
          null,
          "center"
        );
        */
      window.open(pdf.output("bloburl"));
    },
    dayOfWeek(day) {
      if (day.work === 'SÁBADO' || day.work === 'DOMINGO') {
        return 'row-accessories';
      }
    },
    formatarHora(date) {
      if (date) {
        return Moment(date).utc().format("H:mm:ss");
      }
      return;
    },
    formatarDiaSemana(data) {
      let d = new Date(data.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"));
      var semana = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];
      return semana[d.getDay()];
    },
    listPessoas() {
      this.pessoaService.listar().then((data) => {
        this.pessoas = data;
      });
    },
    listOcorrenciasPessoa(codigo) {
      this.pessoaService.listarOcorrencias(codigo).then((data) => {
        this.pessoa = data;
      });
    },
    listarRegistroBatida() {
      if (this.selectedPessoa !== null && this.data !== null) {
        let mes = this.data.getMonth() + 1;
        let ano = this.data.getFullYear();
        this.batidaService
          .listarServidorMensal(this.selectedPessoa, ano, mes)
          .then((data) => {
            if (data.length == 0) {
              this.apresentar = false;
              alert(
                "Não foram encontrados registros de batidas para a data " +
                Moment(this.data).utc().format("DD/MM/YYYY") +
                "."
              );
            } else {
              this.registros = data;
              this.mesAno =
                "" +
                this.data.toLocaleString("default", { month: "long" }) +
                "/" +
                ano;
              this.listOcorrenciasPessoa(this.selectedPessoa);
              this.apresentar = true;
            }
          });
      } else {
        alert("Campo de pessoa e mês são obrigatórios.");
      }
    },
    verificarInconsistencia(dados) {
      if (dados.indexOf("  ENTRADA  |  ENTRADA  ") != -1) {
        return "DOIS OU MAIS REGISTROS DE ENTRADAS.";
      } else if (dados.indexOf("  SAÍDA  |  SAÍDA") != -1) {
        return "DOIS OU MAIS REGISTROS DE SAÍDAS.";
      } else if (dados === "SAÍDA") {
        return "SOMENTE REGISTRO DE SAÍDA.";
      } else if (dados === "ENTRADA") {
        return "SOMENTE REGISTRO DE ENTRADA.";
      } else {
        return "SEM INCONSISTÊNCIA.";
      }
    },
    dadosUser(cpf) {
      this.pessoaService.listarOcorrenciasPorCpf(cpf).then((data) => {
        this.pessoa = data;        
      });
      this.mesAno = Moment(this.form.data).locale('pt-br').format("MMMM")
        + "/"
        + this.form.data.getFullYear();        
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.login {
  background-color: #fff;
}

.bodyForm {
  padding: 50px;
}

.responsive {
  width: 70%;
}

.p-datatable-sm .p-datatable-tbody>tr>td .p-column-title {
  display: none;
}

#dadosPonto {
  font-size: 12.5px;
}

.fundo {
  height: 100vh;
  overflow: hidden;
  margin-left: -5px;
  background-image: url("../../../public/assets/layout/images/sespa/fundo.png");
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
  /* Resize the background image to cover*/
}

.product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.status-Entrada {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-Saída {
    background: #ffcdd2;
    color: #c63737;
  }
}

@media screen and (max-width: 40em) {
  ::v-deep(.p-datatable) {
    &.p-datatable-sm {

      .p-datatable-thead>tr>th,
      .p-datatable-tfoot>tr>td {
        display: none !important;
      }

      .p-datatable-tbody>tr>td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;

        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4em 1em -0.4em -0.4rem;
          font-weight: bold;
        }

        &:last-child {
          border-bottom: 1px solid var(--surface-d);
          text-align: left;
        }

        .p-rating {
          display: inline-block;
        }
      }
    }
  }
}

@media print {
  .print {
    display: none;
  }

  .return {
    display: none;
  }
}
</style>